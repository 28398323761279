export default {
  data() {
    return {
      showConfirmTicket: false
    }
  },
  methods: {
    // 点击兑换票券按钮后，弹出票券兑换窗口
    showConfirm() {
      this.showConfirmTicket = true
      this.$bus.$emit('blurScreen')
    },
    // 监听到 hide事件后隐藏票券兑换窗口
    hiddenModal(type) {
      this.$bus.$emit('clearBlur')
      this[type] = false
    },
    // 处理票券核销
    handleConfirmTicket(code) {
      this.$http.ticket.confirmTicket({ code: code }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    }
  }
}
