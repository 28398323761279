<template>
  <div class="confirm-ticket">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="30vh"
               class="confirm-ticket-dialog">
      <div slot="title"
           class="confirm-ticket-form__header">
        <span>核销票券</span>
      </div>
      <el-form ref="confirmTicket"
               :model="form"
               label-position="right"
               label-width="auto"
               :rules="rules">
        <div class="confirm-ticket-form__body">
          <el-form-item prop="code">
            <el-input v-model="form.code"
                      placeholder="请输入票券兑换码"
                      maxlength="12"
                      clearable></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer"
           class="confirm-ticket-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleSubmit('confirmTicket')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    let validateTicket = (rule, value, callback) => {
      // let regExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{11,}$/
      let regExp = /^[A-Za-z0-9]*$/
      if (!value) {
        return callback(new Error('请输入票券兑换码'))
      } else if (!regExp.test(value)) {
        return callback(new Error('兑换码只包含数字、英文或数字英文组合'))
      } else {
        return callback()
      }
    }
    return {
      form: { code: '' },
      visible: false,
      dialogWidth: '0', // 窗口宽度
      rules: {
        code: [
          { validator: validateTicket, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.form.code = ''
    this.visible = true
    this.setDialogWidth()
    // 监听浏览器窗口大小变化，用于分配记账单位窗口的自适应
    window.addEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  beforeDestroy() {
    window.addEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  methods: {
    // 点击确认按钮，弹出输入的票券兑换码
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('submit', this.form.code)
        } else {
          return false
        }
      })
    },
    // 点击取消，关闭弹窗
    handleCancel() {
      this.visible = false
      this.$emit('hide')
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
      } else if (windowWidth > 414) {
        this.dialogWidth = '330px'
      }
    }
  }
}
</script>

<style lang="scss">
.confirm-ticket-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 49px 40px 43px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.confirm-ticket {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 260px;
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .confirm-ticket-dialog {
    .el-dialog__body {
      padding: 32px 20px;
    }
  }
  .confirm-ticket-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 260px;
        }
      }
    }
  }
}
</style>
