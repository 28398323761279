import axios from '../../plugins/axios'

const getAccountList = (options) => axios.post('/user/getAll', options)

const addAccount = (options) => axios.post('/user/create', options)

export default {
  getAccountList,
  addAccount
}
