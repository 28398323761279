import axios from '../../plugins/axios'
// 登录
const login = (options) => axios.post('/login', options)
// 初始化
const init = (options) => axios.post('/init/createSuperAdmin', options)

const checkInit = (options) => axios.get('/init/checkInit')

const logout = (options) => axios.post('/logout')

const userTicketList = (options) => axios.post('/user/getUserTicketList', options)

const getUserMenu = (options) => axios.get('menu/queryMenuBySessionId', { params: options })

export default {
  login,
  init,
  checkInit,
  logout,
  userTicketList,
  getUserMenu
}
