
import timeFormat from '../utils/timeFormat.js'

export function timeFilter (val, formmat) {
  if (val) {
    return timeFormat(val, formmat)
  } else {
    return ''
  }
}
