<template>
  <div id="app"
       class="app"
       ref="app">
    <transition-router>
      <router-view />
    </transition-router>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {

    }
  },
  created() { },
  mounted() {
    this.$bus.$on('blurScreen', () => {
      this.$refs.app.style.filter = 'blur(2px)'
    })
    this.$bus.$on('clearBlur', () => {
      this.$refs.app.style.filter = 'blur(0)'
    })
  },
  beforeDestroy() {
    this.$bus.$off('blurScreen')
    this.$bus.$off('clearBlur')
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  /*color: rgba(255, 255, 255, 0.9);*/
  position: relative;
}
</style>
