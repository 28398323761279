'use strict'

import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { MessageBox } from 'element-ui'
import { getToken } from '@/utils/localStorage.js'
import router from '@/router'
// import Qs from 'qs'

let config = {
  baseURL: window.BASE_URL,
  timeout: 10000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = getToken()
    if (!config.headers.hasOwnProperty('sessionId') && token) {
      config.headers['sessionId'] = token
      // config.headers['X-Auth-Token'] = token
    }
    console.log(config)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  response => {
    const res = response.data

    if (res.code === 50005) {
      const isAsking = store.state.isAskLogin

      if (!isAsking) {
        store.commit('SET_IS_ASK_LOGIN', true)
        MessageBox.confirm('您当前已登录超时或未登录，请重新登录', '重新登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          router.push('/login')
        })
      }
    } else {
      return response
    }
  },
  error => {
    return Promise.reject(error)
  }
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default _axios
