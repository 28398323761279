import axios from '../../plugins/axios'

const addTicket = (options) => axios.post('/ticket/createTicket', options)

const getTicketList = (options) => axios.post('/ticket/ticketList', options)
const updateTicket = (options) => axios.put('/ticket/updateTicket', options)

// const confirmTicket = (options) => axios.post('/ticket/exchange', options)
const confirmTicket = (options) => axios.post('/ticket/exchange', options)

const getCreatorList = () => axios.get('/ticket/creatorList')

const getVerificationList = (options) => axios.post('/ticket/exchangeList', options)

const userTicketsSend = (options) => axios.post('/user/quickGiveOutTicket', options) // 一键发券

// 平台发券给商户
const platSendTicket = (options) => axios.post('/user/platformQuickGiveOutTicket', options)

const getTicketRecordList = (options) => axios.post('/ticket/giveOutRecord', options)

const getCurrentUserTicketType = () => axios.get('/ticket/getCurrentUserTicketType')

const getTicketDetailList = (options) => axios.post('/ticket/ticketDetailList', options)
// 票券流转
const ticketRecord = (options) => axios.get('/ticket/getTicketTransferRecord', { params: options })

export default {
  addTicket,
  updateTicket,
  getTicketList,
  confirmTicket,
  getCreatorList,
  getVerificationList,
  userTicketsSend,
  getTicketRecordList,
  getCurrentUserTicketType,
  getTicketDetailList,
  platSendTicket,
  ticketRecord

}
