import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/Layout/index.vue'
import { _local } from '@/utils/localStorage'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/units',
      name: 'units',
      component: Layout,
      redirect: '/units/unitsManagement',
      meta: { title: '记账单位管理', verification: true, icon: 'ym-icon-shouye' },
      children: [
        {
          path: 'unitsManagement',
          name: 'units-management',
          component: () => import('../views/unitsManagement/index.vue'),
          meta: {
            menuShow: true,
            title: '记账单位总览',
            icon: 'el-icon-notebook-1',
            verification: true
          }
        },
        {
          path: 'unitsManagementPerson',
          name: 'units-management-person',
          component: () => import('../views/unitsManagement/index2.vue'),
          meta: {
            menuShow: true,
            title: '我的记账单位',
            icon: 'el-icon-notebook-2',
            verification: true
          }
        },
        {
          path: 'unitsDetail',
          name: 'units-detail',
          hidden: true,
          component: () => import('../views/unitsManagement/unitsDetail/index.vue'),
          meta: { menuShow: false, title: '记账单位详情', verification: true }
        },
        {
          path: 'unitsDetailPerson',
          name: 'units-detail-person',
          hidden: true,
          component: () => import('../views/unitsManagement/unitsDetail/unitsDetailPerson.vue'),
          meta: { menuShow: false, title: '记账单位详情', verification: true }
        }
      ]
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: Layout,
      redirect: '/tickets/ticketsManagement',
      // hidden: true,
      meta: { title: '票券管理', verification: true, icon: 'el-icon-s-ticket' },
      children: [
        {
          path: 'ticketsManagement',
          name: 'tickets-management',
          component: () => import('../views/tickets/index.vue'),
          meta: {
            menuShow: true,
            title: '票券列表',
            icon: 'el-icon-s-ticket',
            verification: true
          }
        },
        {
          path: 'ticketsType',
          name: 'tickets-type',
          component: () => import('../views/tickets/type/index.vue'),
          meta: {
            menuShow: true,
            title: '类型管理',
            icon: 'el-icon-s-ticket',
            verification: true
          }
        },
        {
          path: 'ticketsDetail',
          name: 'tickets-detail',
          component: () => import('../views/tickets/ticketsDetail/index.vue'),
          hidden: true,
          meta: {
            menuShow: true,
            title: '票券详情',
            verification: false
          }
        },
        {
          path: 'ticketsDetail/ticketTransferRecord',
          name: 'ticket-transfer-record',
          hidden: true,
          component: () => import('../views/tickets/ticketsDetail/ticketTransferRecord/index.vue'),
          meta: {
            menuShow: false,
            title: '流转记录',
            verification: false
          }
        }
      ]
    },
    {
      path: '/management',
      name: 'management',
      component: Layout,
      meta: { title: '用户管理', icon: 'el-icon-menu', verification: true },
      children: [
        {
          path: 'accountManagement',
          name: 'account-management',
          component: () => import('../views/accountManagement/index.vue'),
          meta: {
            title: 'B端用户管理',
            menuShow: true,
            icon: 'el-icon-s-management',
            verification: true
          }
        },
        {
          path: 'userManagement',
          name: 'user-management',
          // hidden: true,
          component: () => import('../views/user/index.vue'),
          meta: {
            title: 'C端用户管理',
            menuShow: true,
            icon: 'el-icon-user',
            verification: true
          }
        }
      ]
    },
    {
      path: '/verification',
      name: 'verification',
      // hidden: true,
      component: Layout,
      meta: { title: '核销记录', icon: 'ym-icon-shuju', verification: true },
      children: [
        {
          path: 'verificationManagement',
          name: 'verification-management',
          component: () => import('../views/verification/index.vue'),
          meta: {
            title: '核销记录',
            menuShow: true,
            icon: 'ym-icon-shuju',
            verification: true
          }
        }
      ]
    },
    {
      path: '/records',
      name: 'records',
      component: Layout,
      meta: { title: '发券记录', verification: true },
      children: [
        {
          path: 'ticketRecords',
          name: 'ticket-records',
          component: () => import('../views/ticketRecords/index.vue'),
          meta: {
            title: '发券记录',
            menuShow: true,
            icon: 'el-icon-document',
            verification: true
          }
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      hidden: true,
      component: () => import('../views/login'),
      meta: { menuShow: false, menuName: '登录', verification: false }
    },
    {
      path: '/register',
      name: 'register',
      hidden: true,
      component: () => import('../views/register/index.vue'),
      meta: { menuShow: false, menuName: '注册', verification: false }
    },
    {
      path: '/',
      hidden: true,
      redirect: () => {
        const arr = _local.get('router')
        if (arr && arr.length) {
          // let bool = arr.some(item => {
          //   if (item.pathName === 'units') {
          //     return true
          //   } else {
          //     return false
          //   }
          // })
          return arr[0].path
        }
        return '/init'
      },
      meta: { menuShow: false, verification: false }
    },
    {
      path: '/home',
      hidden: true,
      redirect: () => {
        const arr = _local.get('router')
        if (arr && arr.length) {
          let bool = arr.some(item => {
            if (item.pathName === 'units') {
              return true
            } else {
              return false
            }
          })
          if (bool) {
            return '/units/unitsManagement'
          } else {
            return '/tickets/ticketsManagement'
          }
        }
        return '/init'
      },
      meta: { menuShow: false, verification: false }
    },
    {
      path: '/init',
      hidden: true,
      component: () => import('../views/init/index.vue'),
      meta: { menuShow: false, menuName: '初始化平台', verification: false }
    },
    {
      path: '/annual',
      hidden: true,
      component: () => import('../views/annual/index.vue'),
      meta: { menuShow: false, menuName: '年会抽奖', verification: false }
    }
  ]
})

export default router
