 <template>
  <el-header class="ym-header"
             :style="{ height: menuMargin }">
    <div class="headerWrap"
         :style="headerStyle">

      <!-- 左侧logo盒子 -->
      <div class="logo-box"
           v-if="logoOptions"
           :style="{left: logoOptions.left}"
           @click="logoClick">
        <logo class="logo-box-info hidden-xs-only"
              :logoOptions="logoOptions"></logo>
        <div class="hidden-sm-and-up">
          <!--移动端显示菜单按钮-->
          <menu-icon :is-active="sidebar.opened"
                     @toggleClick="toggleSideBar"
                     class="admin-menu-icon" />
        </div>
      </div>

      <div class="headerMiddle hidden-xs-only"
           v-if="showWelcome && userName">
        <span>欢迎{{userName}}，登录发券平台</span>
      </div>

      <div class="headerRight">
        <!-- <div class="theme">
          主题色：
          <el-radio-group v-model="themeCheck" @change="onThemeChange">
            <el-radio-button label="theme-default">默认</el-radio-button>
            <el-radio-button label="theme-yimi">蚁米色</el-radio-button>
          </el-radio-group>
        </div> -->
        <div class="userInfo">
          <div class="confirm">
            <el-button class="confirm-btn"
                       type="primary"
                       @click="showConfirm">核销票券</el-button>
          </div>
          <div class="headerImg"
               @click="headerImgClick">
            <img :src="userIcon"
                 v-errorAlt="'photo'"
                 alt
                 style="width:100%;height:100%">
          </div>
          <el-dropdown class="right-menu"
                       trigger="click">
            <span class="el-dropdown-link">
              {{userName||'游客'}}<i class="el-icon-caret-bottom"
                 style="margin: 0px 15px; color:#A5A6A6"></i>
            </span>
            <el-dropdown-menu class="ym-header-dropdown"
                              slot="dropdown">
              <el-dropdown-item v-for="item in rightMenu"
                                :key="item.url">
                <router-link :disabled="item.url?false:true"
                             :to="{path:item.url}"><i :class="item.icon"></i>{{item.title}}</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <div @click="loginOut"><i class="el-icon-position"></i>退出</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!--         <div class="imgWrap">
          <img src="../../assets/img/exit.png" width="28" alt title="退出登录" @click="loginOut">
        </div> -->
      </div>
    </div>
    <confirm-tickets v-if="showConfirmTicket"
                     @hide="hiddenModal('showConfirmTicket')"
                     @submit="handleConfirmTicket"></confirm-tickets>
  </el-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import baseUrl from '@/config'
import { _local } from '../../utils/localStorage'
import Logo from './Sidebar/Logo'
import MenuIcon from './Sidebar/MenuIcon'

import confirmTickets from '@/views/confirmTickets/index.vue'
import confirmTicketMixin from './mixin/confirmTicket.js'

export default {
  mixins: [confirmTicketMixin],
  components: {
    MenuIcon,
    Logo,
    confirmTickets
  },
  data() {
    return {
      themeCheck: 'theme-default',
      headerStyle: {}, // 顶部栏外层样式
      menuMargin: 0, // 菜单距离
      userName: ''
    }
  },
  props: {
    // 定位规则,顶部栏是否固定在窗口顶部, 可选:absolute(不固定)/fixed(固定)
    position: {
      type: String,
      default: 'absolute'
    },

    // 顶部栏背景色
    background: {
      type: String,
      default: '#eee'
    },

    // 顶部栏高度
    height: {
      type: String,
      default: '73'
    },

    // 显示欢迎语
    showWelcome: {
      type: Boolean,
      default: true
    },

    // 在顶部栏处于固定在窗口上 方的情况下,页面滚动的时候 顶部栏所需改变的颜色 ,前置 条件:需position为fixed
    fixedColor: {
      type: String,
      default: '#eee'
    },

    // 顶部栏内容过多或响应宽度过窄时的换行规则.
    // auto表示发生换行,clip不换行 超出模块隐藏, ellipsis不换行显示省略符号来代表被修剪的内容
    wrapStyle: {
      type: String,
      default: 'auto'
    },

    // 右侧下拉菜单
    rightMenu: {
      type: Array,
      default: () => {
        return []
      }
    },

    logoOption: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted() {
    this.initParam()

    window.addEventListener('scroll', this.handleScroll, true)
  },
  computed: {
    logoOptions() {
      return Object.assign({
        overflow: true
      }, this.logoOption)
    },
    ...mapGetters(['userInfo', 'sidebar']),
    userIcon() {
      if (!this.userInfo) {
        return ''
      }
      if (this.userInfo.userIcon.indexOf('http') === -1) {
        // return baseUrl.baseUrl + '/' + this.userInfo.userIcon
        return window.BASE_URL + '/' + this.userInfo.userIcon
      }
      return this.userInfo.userIcon
    }
  },
  methods: {
    // 初始化参数
    initParam() {
      let styleObj = {}

      // 初始化顶部栏定位规则
      // styleObj.position = this.position === 'absolute' ? 'absolute' : 'fixed'
      styleObj.position = 'relative'

      // 初始化顶部栏背景颜色
      styleObj.background = this.background

      // 初始化顶部栏高度
      styleObj.height = (Number(this.height) - 1) + 'px'
      this.menuMargin = this.height + 'px'

      this.$set(this, 'headerStyle', styleObj)

      // 初始化用户名
      this.userName = _local.get('userName')
    },

    // logo点击事件
    logoClick() {
      this.$emit('logoClick')
    },

    // 头像点击事件
    headerImgClick() {
      this.$emit('headerImgClick')
    },

    // 滚动条滚动事件
    handleScroll(e) {
      // 仅当fixed定位时开启颜色变换
      if (this.position === 'absolute') {
        return
      }
      // 当前滚动条位置
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      // 滚动大于150变换完毕
      if (scrollTop > 150) {
        this.$set(this.headerStyle, 'background', this.fixedColor)
      } else {
        // 原始颜色
        let colorStart = this.convertHexToRGB(this.background)
        // 目标颜色
        let colorEnd = this.convertHexToRGB(this.fixedColor)
        // 计算过渡色
        let i = scrollTop / 150
        let colorApply = {
          r: (colorEnd.r - colorStart.r) * i + colorStart.r,
          g: (colorEnd.g - colorStart.g) * i + colorStart.g,
          b: (colorEnd.b - colorStart.b) * i + colorStart.b
        }
        let colorApplyStr = 'rgba(' + colorApply.r + ',' + colorApply.g + ',' + colorApply.b + ')'
        this.$set(this.headerStyle, 'background', colorApplyStr)
      }
    },

    // 颜色十六进制转rbg
    convertHexToRGB(color) {
      if (color.length === 4) {
        let extendedColor = '#'
        for (let i = 1; i < color.length; i++) {
          extendedColor += color.charAt(i) + color.charAt(i)
        }
        color = extendedColor
      }
      const values = {
        r: parseInt(color.substr(1, 2), 16),
        g: parseInt(color.substr(3, 2), 16),
        b: parseInt(color.substr(5, 2), 16)
      }
      return values
    },
    ...mapActions(['logout']),
    loginOut() {
      // removeToken()
      this.$http.user.logout().then(res => {
        if (res.data.code === 0) {
          sessionStorage.clear()
          localStorage.clear()
          this.$router.push('/login')
        } else {
          sessionStorage.clear()
          localStorage.clear()
          this.$router.push('/login')
        }
      }).catch(err => {
        this.$message({
          type: 'error',
          message: err
        })
        sessionStorage.clear()
        localStorage.clear()
        this.$router.push('/login')
      })
      // this.logout()
    },
    onThemeChange(val) {
      this.toggleTheme(document.body, val)
    },
    /**
     * @param {HTMLElement} element
     * @param {string} className
     */
    toggleTheme(element, className) {
      if (!element || !className) {
        return
      }
      let classArr = element.className.split(' ')
      classArr.forEach((item, index) => {
        if (item.indexOf('theme-') !== -1) {
          classArr.splice(index, 1)
        }
      })
      classArr.push(className)
      element.className = classArr.join(' ')
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    }
  }
}
</script>
<style lang="scss">
.ym-header {
  padding: 0 !important;
  margin: 0;
  text-align: right;
  font-size: 12px;
  background: $--header-bg-color;
  color: #333;
  line-height: $--header-height;
  border-bottom: $--header-border;
  width: 100%;
  overflow: hidden;
  z-index: 1000;

  .headerWrap {
    height: 100%;
    box-sizing: border-box;
    width: 100%;

    .logo-box {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      overflow: hidden;
      &-info {
        display: initial;
      }
    }

    .headerMiddle {
      float: left;
      margin-left: $--aside-width + 32px;
      height: 100%;
      color: $--color-text-secondary;
      font-size: $--font-size-medium;
      user-select: none;
    }

    .headerRight {
      height: 100%;
      // display: flex;
      // align-items: center;
      // justify-content: flex-end;
      float: right;
      .introduce {
        color: #ff4a12;
        padding-bottom: 5px;
        border-bottom: 1px solid #ff4a12;
        color: #ff4a12;
        font-size: 14px;
        margin-right: 80px;
        font-weight: 400;
        cursor: pointer;
      }
      .theme {
        padding-right: 20px;
      }
      .userInfo {
        font-size: 16px;
        color: #666;
        display: flex;
        align-items: center;
        margin-right: 25px;
        .confirm {
          margin-right: 40px;
        }
        div {
          margin: 0px 10px;
        }
        .right-menu {
          color: $--color-text-primary;
          width: fit-content;
          user-select: none;
          cursor: pointer;
        }
        .el-dropdown-link,
        .el-dropdown-link:focus {
          outline: none;
        }
        .headerImg {
          display: inline-block;
          width: $--header-avatar-width;
          height: $--header-avatar-width;
          border-radius: 50%;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }

      .imgWrap {
        display: flex;
        align-items: center;

        img {
          display: block;
          margin-right: 40px;
          border: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.el-dropdown-menu__item {
  i {
    margin-right: 5px;
  }
}

a {
  color: $--color-text-regular;
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
.ym-header-dropdown {
  a {
    color: $--color-text-primary;
  }
}
</style>
