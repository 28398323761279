import LZString from 'lz-string'

const TokenKey = 'sessionId'

const UserInfo = 'ym-certificate-chain-userinfo'

const ActiveMenu = 'ym-certificate-chain-active-menu'

const _local = {

  // localStorage存储
  set(k, v) {
    if (!k) {
      // console.error('键值不合法. 无效的键值: ' + k)
      return
    }
    v = LZString.compress(JSON.stringify(v))
    window.localStorage.setItem(k, v)
  },

  // localStorage 获取数据
  get(k) {
    let item = window.localStorage.getItem(k)
    if (!item) {
      // console.log('本地未存储键值为: ' + k + ' 的值')
      return item
    }
    try {
      return JSON.parse(LZString.decompress(item))
    } catch (e) {
      // console.warn('LZString解密失败，直接返回原值')
      return item
    }
  },

  // localStorage删除数据
  remove(k) {
    window.localStorage.removeItem(k)
  },
  // localStorage清除全部
  clear() {
    window.localStorage.clear()
  }
}

const _session = {

  set(k, v) {
    if (!k) {
      // console.error('键值不合法. 无效的键值: ' + k)
      return
    }
    v = LZString.compress(JSON.stringify(v))
    window.sessionStorage.setItem(k, v)
  },

  get(k) {
    let item = window.sessionStorage.getItem(k)
    if (!item) {
      // console.log('本地未存储键值为: ' + k + ' 的值')
      return item
    }
    try {
      return JSON.parse(LZString.decompress(item))
    } catch (e) {
      // console.warn('LZString解密失败，直接返回原值')
      return item
    }
  },

  remove(k) {
    window.sessionStorage.removeItem(k)
  },

  clear() {
    window.sessionStorage.clear()
  }
}

export function getToken() {
  return _local.get(TokenKey)
}

export function setToken(token) {
  return _local.set(TokenKey, token)
}

export function removeToken() {
  return _local.remove(TokenKey)
}

export function getUserInfo() {
  return _local.get(UserInfo)
}

export function setUserInfo(info) {
  return _local.set(UserInfo, info)
}

export function removeUserInfo() {
  return _local.remove(UserInfo)
}

export function getActiveMenu() {
  return _local.get(ActiveMenu)
}

export function setActiveMenu(active) {
  return _local.set(ActiveMenu, active)
}

export {
  _local,
  _session
}
