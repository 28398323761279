<template>
  <el-container :class="classObj"
                class="ym-layout"
                style="flex-direction: column">
    <div v-if="device==='mobile'&&sidebar.opened"
         class="drawer-bg"
         @click="handleClickOutside" />
    <hearder position="fixed"
             background="#fff"
             height="73"
             showWelcome
             :rightMenu='rightMenu'
             :logoOption='logoOption'
             @logoClick='logoClick'
             @headerImgClick='headerImgClick'></hearder>
    <el-container class="ym-body">
      <el-aside>
        <ym-menu :routes="routers"
                 :logoOption='logoOption'></ym-menu>
      </el-aside>
      <el-main>
        <!-- <breadcrumb :bottom="20"></breadcrumb> -->
        <router-view class="ym-content" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Hearder from './Header.vue'
import ymMenu from './Sidebar/index.vue'
import ResizeMixin from './mixin/ResizeHandler'
import { _local } from '../../utils/localStorage'

// import Menu from "./Menu.vue";
// import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    Hearder,
    ymMenu
    // Menu,
  },
  mixins: [ResizeMixin],
  created() { },
  data() {
    return {
      routes: [
        {
          path: '/home',
          name: 'home',
          meta: { icon: 'el-icon-s-home', title: '首页' }
        },
        {
          path: '/example',
          name: 'example',
          redirect: '/example/list',
          meta: { icon: 'el-icon-s-help', title: '示例' },
          children: [
            {
              path: '/home2',
              name: 'home2',
              meta: { icon: 'el-icon-s-help', title: 'home2' }
            },
            {
              path: '/example2',
              name: 'example2',
              redirect: '/example/list',
              meta: { icon: 'el-icon-s-help', title: '组件' },
              children: [
                {
                  path: 'home2',
                  name: 'example2home2',
                  meta: { icon: 'el-icon-s-help', title: 'example2-home2' }
                },
                {
                  path: 'example2',
                  name: 'example2example2',
                  redirect: '/example/list',
                  meta: { icon: 'el-icon-s-help', title: 'exam2-exam2' }
                }
              ]
            }
          ]
        },
        {
          path: '/example3',
          name: 'example3',
          hidden: 'true',
          redirect: '/example/list',
          meta: { icon: 'el-icon-s-help', title: 'example3' },
          children: [
            {
              path: '/home3',
              name: 'home3',
              meta: { icon: 'el-icon-s-help', title: '父节点hidden' }
            }
          ]
        },
        {
          path: '/example4',
          name: 'example4',
          redirect: '/example/list',
          hidden: 'true',
          meta: { icon: 'el-icon-s-help', title: '子节点全隐藏的父节点' },
          children: [
            {
              path: '/home5',
              name: 'home5',
              hidden: 'true',
              meta: { icon: 'el-icon-s-help', title: '该节点hidden' }
            },
            {
              path: '/example5',
              name: 'example5',
              hidden: 'true',
              redirect: '/example/list',
              meta: { icon: 'el-icon-s-help', title: '该节点hidden' }
            }
          ]
        },
        {
          path: '/test',
          name: 'test',
          meta: { icon: 'el-icon-s-help', title: 'test' }
        }
      ],
      rightMenu: [
      ],
      logoOption: {
        logoType: 'img',
        title: 'YIMI蚁米',
        url: require('./img/logo.png')
      },
      reallRouters: []
    }
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    routers() {
      const routes = JSON.parse(JSON.stringify(this.$router.options.routes))
      let arr = []
      routes.forEach(item => {
        if (item.meta.verification) {
          this.reallRouters.forEach(opt => {
            if (opt.pathName === item.name && opt.parentId === null) {
              arr.push(item)
            }
          })
        }
      })
      arr.forEach(item => {
        item.children.forEach(opt => {
          this.reallRouters.some(obj => {
            if (opt.meta.example || (opt.meta.verification && obj.pathName === opt.name && obj.parentId)) {
              opt.hidden = false
              return true
            } else {
              opt.hidden = true
            }
          })
        })
      })
      console.log(arr)
      return arr || this.$router.options.routes
    }
  },
  mounted () {
    this.reallRouters = _local.get('router')
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    logoClick() {
      // console.log('点击logo')
    },
    headerImgClick() {
      // console.log('点击头像')
    }

  },
  filters: {}
}
</script>

<style lang="scss">
$header-height: $--header-height;
.ym-layout {
  height: 100%;
  border: 1px solid #eee;
  overflow: hidden;
  .el-aside {
    transition: width 0.3s;
    text-align: left;
    color: #333;
    background-color: #fff;
    width: $--aside-width !important;
    box-shadow: $--aside-shadow;
  }
  .system-name {
    height: 76px;
    background-color: #387bfc;
    color: #fff;
    line-height: 76px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .el-main {
    padding: 30px 40px 40px 30px;
    background-color: $--background-color-base;
  }

  &.mobile {
    .el-aside {
      height: 100%;
      position: fixed;
      font-size: 0px;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1002;
    }
    .drawer-bg {
      background: #000;
      opacity: 0.3;
      width: 100%;
      top: 0;
      height: 100%;
      position: fixed;
      z-index: 1001;
    }
  }
  &.hideSidebar.mobile {
    .el-aside {
      width: 0 !important;
    }
  }
  &.hideSidebar {
    & .el-aside {
      width: 64px !important;
      transition: width 0.3s;
      & .el-menu-item.is-active::before {
        width: 0px;
      }
    }
    .el-menu--collapse .el-submenu > .el-submenu__title > span {
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
      display: inline-block;
    }
    .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
      display: none;
    }
    .el-submenu [class^="el-icon-"] {
      position: absolute;
      font-size: $--font-size-medium;
      left: 20px;
      top: 20px;
    }
  }
}

.ym-body {
  background: red;
  height: calc(100% - 73px);
}

.ym-content {
  /*background-color: #fff;*/
  /*box-shadow: 0px 0px 27px 0px rgba(146, 146, 146, 0.28);*/
  /*border-radius: 8px;*/
}
</style>
