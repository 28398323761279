import axios from '../../plugins/axios'

const uploadFile = (options) => axios.post('/upload', options)

const removeFile = (options) => axios.delete('/deleteUpload', { params: options })

export default {
  uploadFile,
  removeFile
}
