import axios from '../../plugins/axios'

// const loginDraw = (options) => axios.get('/url', {
//   params: {
//     options: options
//   }
// })

const getDrawList = () => axios.get('/draw/list')

const draw = (options) => axios.post('/draw', options)

export default {
  // loginDraw,
  getDrawList,
  draw
}
