import axios from '../../plugins/axios'

const getUnitList = (options) => axios.post('/accounting/accountingList', options)

const getUserUnitList = (type) => axios.get('/accounting/queryUserAccountingList', {
  params: {
    type: type
  }
})

const addUnit = (options) => axios.post('/accounting/createAccounting', options)

const allocateUnit = (options) => axios.post('/accounting/divideAccounting', options)

const getUnit = (options) => axios.get('/accounting/getAccounting', {
  params: options
})

const getUnitDetail = (options) => axios.post('/accounting/userAccountingList', options)

const unitTotal = (options) => axios.get('/accounting/queryUserAccountingTotalNumber', { params: options })
const getPersonUnitList = (options) => axios.post('/accounting/queryUserAccountingPage', options)
const getPersonUnitDetail = (options) => axios.post('/ticket/getTicketListByAccountingId', options)

export default {
  getUnitList,
  getUserUnitList,
  addUnit,
  allocateUnit,
  getUnit,
  getUnitDetail,
  unitTotal,
  getPersonUnitList,
  getPersonUnitDetail
}
