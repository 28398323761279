import axios from '../../plugins/axios'

const getTicketTypeList = (options) => axios.post('/ticketType/queryTicketTypeList', options)

const addTicketType = (options) => axios.post('/ticketType/create', options)
const updateTicketType = (options) => axios.put('/ticketType/update', options)
const getTicketTypeTags = (options) => axios.get('/ticketType/getTicketTypeTags', { params: options })

const getTicketTypeDetail = (options) => axios.get('/ticketType/getById', { params: options })
const deleteTicketType = (options) => axios.delete('/ticketType/delete', { params: options })

// 票券主题
const addTicketTheme = (options) => axios.post('/ticket/createTicketTheme', options)
const getTicketThemeList = (options) => axios.get('/ticket/getTicketThemeList', { params: options })

export default {
  getTicketTypeList,
  addTicketType,
  updateTicketType,
  getTicketTypeTags,
  getTicketTypeDetail,
  deleteTicketType,
  addTicketTheme,
  getTicketThemeList
}
