import login from '@/service/modules/user.js'
import { getToken, setToken, removeToken, getUserInfo, setUserInfo, removeUserInfo } from '@/utils/localStorage.js'
import router from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  userInfo: getUserInfo(),
  isAskLogin: false
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = JSON.stringify(info)
  },
  SET_IS_ASK_LOGIN: (state, val) => {
    state.isAskLogin = val
  }
}

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login.login(userInfo).then(response => {
        const { data } = response
        if (data.code === 200) {
          commit('SET_TOKEN', data.data)
          commit('SET_NAME', data.data.companyName)
          commit('SET_USER_INFO', data.data)
          setToken(data.data)
          setUserInfo(JSON.stringify(data.data))
          resolve(data.data.companyTypeId)
        } else {
          reject(data.message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_USER_INFO', '')
      removeToken()
      removeUserInfo()
      resolve()
    })
  },
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      login.logout().then(() => {
        commit('SET_TOKEN', '')
        commit('SET_USER_INFO', '')
        removeToken()
        removeUserInfo()
        resolve()
        router.push({ path: '/login' })
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namescaped: false,
  state,
  mutations,
  actions
}
