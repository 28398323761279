import router from '@/router'
import axios from '@/plugins/axios'
import { _local } from './localStorage.js'

// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
  if (_local.get('hasInit') === 1) {
    if (to.path === '/init') {
      next('/')
    } else {
      next()
    }
  } else {
    if (to.path === '/login' || to.path === '/init' || to.path === '/register') {
      next()
    } else {
      axios.get(window.BASE_URL + '/init/checkInit').then(res => {
        if (res.data.code === 0) {
          if (res.data.data) {
            if (to.path === '/init') {
              next()
            } else {
              next('/init')
            }
          } else {
            // _local.set('hasInit', 1)
            next('/login')
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
})
