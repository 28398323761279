<template>
  <el-menu class="ym-menu"
           :default-active="activeMenu"
           :collapse="isCollapse"
           :unique-opened="false"
           :collapse-transition="false"
           mode="vertical">
    <div class="logo-box hidden-sm-and-up"
         v-if="menuLogo">
      <logo class="logo-box-info"
            :logoOptions="logoOptions"></logo>
    </div>
    <sidebar-item v-for="route in routes"
                  :key="route.path"
                  :item="route"
                  :base-path="route.path" />
    <div class="admin-menu hidden-xs-only">
      <menu-icon :is-active="sidebar.opened"
                 @toggleClick="toggleSideBar"
                 class="admin-menu-icon" />
    </div>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import MenuIcon from './MenuIcon'
import Logo from './Logo'

export default {
  name: 'ymMenu',
  components: {
    Logo,
    SidebarItem,
    MenuIcon
  },
  props: {
    routes: {
      type: Array,
      required: true
    },
    menuLogo: {
      type: Boolean,
      default: false
    },
    logoOption: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['permission_routes', 'sidebar']),
    logoOptions() {
      return Object.assign({
        overflow: true
      }, this.logoOption)
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  created() {
    // console.log(this.logoOption)
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #3679fc;

::v-deep.el-menu {
  border-right: 0 !important;
  background-color: transparent;
  user-select: none;
  a {
    text-decoration: none;
  }

  .el-menu-item {
    padding-left: 30px !important;
    height: $--aside-item-height !important;
    line-height: $--aside-item-height !important;
    font-size: $--font-size-medium;
    color: $--color-text-secondary;
  }
  .el-menu[role="menu"] {
    // padding-left: 20px;
  }
  .el-menu-item [class^="el-icon-"],
  .el-menu-item [class^="ym-icon-"] {
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: $--font-size-medium; //16px
    float: left;
    height: 56px;
    line-height: 56px;
  }
  .el-submenu [class^="el-icon-"],
  .el-submenu [class^="ym-icon-"] {
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: $--font-size-medium; //16px
    float: initial;
  }
  .el-submenu .el-menu-item {
    padding: 0 20px;
    padding-left: 50px !important;
  }
  .el-submenu__title {
    font-size: $--font-size-medium; //16px
    padding-left: 30px !important;
    color: $--color-text-secondary;
  }

  .ym-menu-icon {
    display: inline-block;
    font-size: 24px;
    vertical-align: middle;
    margin-right: 14px;
  }
  .el-submenu__icon-arrow {
    font-size: $--font-size-medium;
    transform: rotate(-90deg);
  }
  .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
    transform: rotateZ(0);
  }
  .el-menu-item.is-active {
    font-size: $--font-size-medium;
    font-weight: $--aside-font-active-weight;
    color: $--color-primary;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 56px;
      background-color: $--color-primary;
    }
    & [class^="el-icon-"] {
      font-size: $--font-size-medium; //16px
    }
    & [class^="ym-icon-"] {
      font-size: $--font-size-medium; //16px
    }
  }
  .el-submenu.is-active {
    color: $--color-primary;
    font-weight: $--aside-font-active-weight;
    & [class^="el-icon-"] {
      font-size: $--font-size-medium; //16px
    }
    & [class^="ym-icon-"] {
      font-size: $--font-size-large; //18px
    }
    .el-submenu__title {
      color: $--color-primary;
      i {
        color: $--color-primary;
      }
    }
  }

  .logo-box {
    cursor: pointer;
    .sidebar-logo-container {
      height: 72px;
      line-height: 72px;
      overflow: initial;
    }
  }

  .admin-menu {
    position: fixed;
    z-index: 10;
    bottom: 30px;
    right: 20px;
    &-icon {
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 50%;
      background-color: #eee;
      cursor: pointer;
      transition: background 0.3s;
      -webkit-tap-highlight-color: transparent;
      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }
  }
}
</style>
