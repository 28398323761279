import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/permission'
import './assets/light-theme/index.css' // the element-ui-theme css
import './plugins/element.js'
import './assets/css/main.scss'
import myComponents from './components'
import './assets/icon/iconfont.css'
// import velocity from './plugins/velocity'
import * as filters from './filters'
import api from './service'

import '@/directive'

Object.keys(filters).forEach(k => {
  Vue.filter(k, filters[k])
})

Vue.use(myComponents)
// Vue.use(velocity)
// Vue.use(filters)
Vue.config.productionTip = false

Vue.prototype.$http = api

Vue.prototype.$bus = new Vue()

// 兼容ie的vue-router跳转
if (
  '-ms-scroll-limit' in document.documentElement.style &&
  '-ms-ime-align' in document.documentElement.style
) { // detect it's IE11
  window.addEventListener('hashchange', function (event) {
    var currentPath = window.location.hash.slice(1)
    if (store.state.route.path !== currentPath) {
      router.push(currentPath)
    }
  }, false)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
